import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent implements OnInit, OnChanges {
  @Input() DropDownInfo: any;
  @Input() settings: any;
  @Input() SelectedInfo: any;
  @Output() SelectedValues: EventEmitter<any>;
  @Output() onDeSelect: EventEmitter<any>;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  constructor() {
    this.SelectedValues = new EventEmitter<any>();
    this.onDeSelect = new EventEmitter<any>();
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    /*this.DropDownInfo = changes['DropDownInfo']['currentValue'];
    // this.dropdownList = [];
    if(this.selectedItems.length > 0){
      this.selectedItems = [];
    }
    this.setData();*/
  }

  ngOnInit() {
    // this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: this.settings.placeHolder,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      badgeShowLimit: 3,
      groupBy: 'category'
    };
    if (this.settings.DefaultSelect) {
      for (let i = 0; i < this.dropdownList.length; i++) {
        this.selectedItems.push(this.dropdownList[i]);
      }
    }
    this.setData();
  }

  setData() {
    // this.selectedItems = [];
    if (typeof (this.SelectedInfo) === 'undefined') {
      this.SelectedInfo = [];
    }
    this.dropdownList = [];
    for (let i = 0; i < this.DropDownInfo.length; i++) {
      const temp = {
        'id': this.DropDownInfo[i].value,
        'itemName': this.DropDownInfo[i].label,
        'itemValue': this.DropDownInfo[i].value,
        'category': this.DropDownInfo[i].category
      };
      this.dropdownList.push(temp);
    }
    if (this.DropDownInfo[0].category === undefined) {
      delete this.dropdownSettings['groupBy'];
    }
    if (this.settings.FullObject === true) {
      for (let i = 0; i < this.SelectedInfo.length; i++) {
        this.selectedItems.push({
          'id': this.SelectedInfo[i].value,
          'itemName': this.SelectedInfo[i].label,
          'itemValue': this.SelectedInfo[i].value
        });
      }
    } else {
      for (let i = 0; i < this.SelectedInfo.length; i++) {
        for (let j = 0; j < this.DropDownInfo.length; j++) {
          if (this.SelectedInfo[i] === this.DropDownInfo[j].value) {
            this.selectedItems.push({
              'id': this.SelectedInfo[i],
              'itemName': this.DropDownInfo[j].label,
              'itemValue': this.SelectedInfo[i]
            });
          }
        }
      }
    }
    this.SelectedValues.emit(this.SelectedInfo);
  }

  onItemSelect(item: any) {
    this.UtilityFunction(item);
  }
  OnItemDeSelect(item: any) {
    this.UtilityFunction(item);
    this.onDeSelect.emit(item);
  }
  onSelectAll(items: any) {
    this.UtilityFunction(items);
  }
  onDeSelectAll(items: any) {
    this.UtilityFunction(items);
  }
  UtilityFunction(items) {
    let TempObj = {};
    const ObjectPush = [];
    if (this.settings.FullObject === true) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        TempObj = {
          'label': this.selectedItems[i].itemName,
          'value': this.selectedItems[i].itemValue
        };
        ObjectPush.push(TempObj);
      }
      this.SelectedValues.emit(ObjectPush);
    } else if (this.settings.FullObject === false) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        const Temp = this.selectedItems[i].itemValue;
        ObjectPush.push(Temp);
      }
      this.SelectedValues.emit(ObjectPush);
    }
  }
  RefreshFunc() {
    this.selectedItems = [];
  }
}
