import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyStateComponentComponent } from './empty-state-component/empty-state-component.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
  declarations: [EmptyStateComponentComponent],
  imports: [CommonModule,FormsModule,
    NgSelectModule
    ],
  exports: [EmptyStateComponentComponent],
})
export class EmptyStateModule { }
