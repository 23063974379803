import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { AllsetupService } from '../../../services/allsetup.service';
import * as toastr from 'toastr';
import { asyncScheduler } from 'rxjs';

@Component({
  selector: 'app-multi-cohert',
  templateUrl: './multi-cohert.component.html',
  styleUrls: ['./multi-cohert.component.css']
})

export class MultiCohertComponent implements OnInit, OnChanges {
  @Input('inputData') inputData: any;
  @Input('type') type: any;
  @Input('cohertLogic') cohertLogic: any;
  @Input() SCHEDULETASKDETAILS: any;
  @Input() action: any;
  @Input('previousLists') previousLists: any; // this is for inside cohort
  @Input('previousLists1') previousLists1: any; // this is for outside cohort
  @Output() emitConditionalData: EventEmitter<any>;
  @Output() emitDeleteConditionalData: EventEmitter<any>;
  @Input('index') index: any;
  @Input('parentLogic') parentLogic : any;

  console: Console;
  public showConditionBlock = true;
  public levelsContainer: any = [];
  public cancelButton = false;
  public hasResponse = false;
  public ConditionTask = false;
  public SelectInput = false;
  public isEdit = false;
  public conditionObj: any = {
    conditionName: '',
    cohortType: '',
    task: '',
    conditionInTask: '',
    selectInput: [],
    hasResponse: false
  };
  public questionIDs = [];
  public cohortData: any = {};
  public questionsMapping = {};
  public isMR = false;
  public postObject: any = {};
  public cohortingTypes: any = [];
  public selectTaskTypes: any = [];
  public conditionInTaskTypes: any = [];
  show = true;
  showContent = false;
  public operatorBasedQuestions = ['openEndedNumeric', 'sum', 'rating', 'ranking'];
  item: any;
  isValid = true;
  CATEGORY_OPTIONS: any;
  CONDITION_OPTIONS: any = [
    {
      'value': 'Select an option',
      'label': 'Select an option'
    },
    {
      'value': 'Selected a Treatments(s)',
      'label': 'Selected a Treatments(s)'
    },
    {
      'label': 'Selected a button',
      'value': 'Selected a button'
    },
    {
      'label': 'Selected a Screen',
      'value': 'Selected a Screen'
    },
  ];

  condition = [];
  task: any;
  SELECT_INPUT: any = [];

  emailTypeOptions1 = [
    { label: 'Response Based', value: 'Response Based' },
    { label: 'Specific Input', value: 'Specific Input' },

  ];

  multiplelogic = [
    { label: 'AND', value: 'AND' },
    { label: 'OR', value: 'OR' },
  ];

  patient_data;
  public Checked = false;
  public CheckedBox = false;
  selectedCohorts = [];
  configuredData;
  public typeLabel: any = '';
  savedConditionInTask;
  savedInputType;
  public cohortOptions = [];
  testObject = [{
    logic: '',
    tableId: '',
    task: '',
  }]; 
   public questionDetails;
  sendObject: any;
  public single: any;
  constructor(public _service: AllsetupService) {
    this.emitConditionalData = new EventEmitter<any>();
    this.emitDeleteConditionalData = new EventEmitter<any>();
  }

  ngOnInit() {
    this.refreshCohortData();
    this.onLoadFunctions();
  }
  ngOnChanges() {
    this.onLoadFunctions();
  }
  onLoadFunctions() {
    if (this.type === 'inLevel') {
      this.typeLabel = 'Add logic based on previous tasks';
      this.cohortingTypes = this.previousLists['CohortingType'];
    } else {
      this.typeLabel = 'Outside Level Cohorting';
      this.cohortingTypes = this.previousLists1['CohortingType'];
    }
    this.inputData['inLevelCohortSelected'] = this.inputData['inLevelCohortSelected'] == 1 ? 1 : 0;
    this.inputData['outLevelCohortSelected'] = this.inputData['outLevelCohortSelected'] == 1 ? 1 : 0;
    this.postObject = this.inputData;
    this.sendObject = this.inputData;
    this.saveSectionInfo();
  }
  saveSectionInfo() {
    for (const i in this.sendObject['conditions']['conditionsList']) {
      this.sendObject['conditions']['conditionsList'][i]['edit'] = false;
    }
    this.emitConditionalData.emit({ data: this.sendObject.conditions.conditionsList, type: this.action, obj: this.sendObject });
  }
  clone(data) {
    return JSON.parse(JSON.stringify(data));
  }
  cohortingTypeChange(value) {
    if (this.type === 'inLevel') {
      this.selectTaskTypes = this.previousLists['cohortingTypeBased'][value];
    } else {
      this.selectTaskTypes = this.previousLists1['cohortingTypeBased'][value];
    }
  }


  addObjectTreatment(element) {
    if (!element['cohortType'] || !element['conditionName'] || !element['task']) {
      toastr.info('Please fill all the fields', 'Warning');
      return;
    }
    element['conditionsList'] = element['conditionsList'] ? element['conditionsList'] : [];
    element['conditionsList']['type'] = this.type;
    element['conditionsList']['action'] = 'add';
    element['conditionsList']['id'] = '';
    element['conditionsList']['cohortLabels'] = []

    if(this.type == 'inLevel'){
      element['multipleConditionLogic'] = {
        'inLevel': element.logic,
        'outLevel': ""
      }

    }else{
      element['multipleConditionLogic'] = {
        'inLevel': '',
        'outLevel': element.logic
      }
    }
    try {
      if (element.isMR) {
        element['conditionsList']['cohortValue'] = this.getCohortRow(this.questionDetails.type);
        element['conditionsList']['taskType'] = 'MRTask';
        element['taskType'] = 'MRTask';
        element.questionDetails.dataArray.forEach(element => {
          if (element.cohortValue.options.indexOf(element.id) > -1) {
            element.cohortLabels.push(element.value);
          }
        });
      } else if (element.task.includes('_P')) {
        element['conditionsList'].cohortLabels = [];
        element['conditionsList'].selectInput.forEach(selectedOption => {
          element['conditionsList'].SELECT_INPUT.forEach(option => {
            if (selectedOption === option.value) {
              element['conditionsList'].cohortLabels.push(option.label);
              return false;
            }
          });
        });
      } else {
        element['conditionsList'].cohortLabels = element.selectInput;
      }
    } catch (error) {
      console.log(error);
    }
    const tableRow = {
      conditionName: element.conditionName,
      cohortType: element.cohortType,
      task: element.task,
      type: this.type,
      conditionInTask: element.conditionInTask,
      selectInput: element.selectInput,
      hasResponse: element.hasResponse,
      action : 'add',
      id : ''
    }
    element['conditionsList'].push(JSON.parse(JSON.stringify(tableRow)));
    this.emitConditionalData.emit({ data: {}, type: this.action, list: tableRow });

    element.conditionName = '';
    element.cohortType = ''
    this.isEdit = false;
    this.reinitialiseConditionObj();
    this.refreshCohortData();
  }



  updateOption() {
    if (!this.conditionObj['cohortType'] || !this.conditionObj['conditionName'] || !this.conditionObj['task']) {
      toastr.info('Please fill all the fields', 'Warning');
      return;
    }
    if (this.conditionObj['hasResponse']) {
      this.conditionObj['task'] = this.conditionObj['task'];
    }
    this.conditionObj['action'] = 'edit';
    this.conditionObj['type'] = this.action;
    if (this.isMR) {
      this.conditionObj['cohortValue'] = this.getCohortRow(this.questionDetails.type);
      this.conditionObj['taskType'] = 'MRTask';
      this.questionDetails.dataArray.forEach(element => {
        if (this.conditionObj.cohortValue.options.indexOf(element.id) > -1) {
          this.conditionObj.cohortLabels.push(element.value);
        }
      });
    } else if (this.conditionObj.task.includes('_P')) {
      this.conditionObj.cohortLabels = [];
      this.conditionObj.selectInput.forEach(selectedOption => {
        this.SELECT_INPUT.forEach(option => {
          if (selectedOption === option.value) {
            this.conditionObj.cohortLabels.push(option.label);
            return false;
          }
        });
      });
    } else {
      this.conditionObj.cohortLabels = this.conditionObj.selectInput;
    }
    this.postObject['conditionsList'][this.conditionObj['index']] = JSON.parse(JSON.stringify(this.conditionObj));
    this.reinitialiseConditionObj();
    this.emitConditionalData.emit({ data: {}, type: this.action, list: this.postObject });
    this.isEdit = false;
  }
  editList(condition, index) {
    this.isEdit = true;
    this.showConditionBlock = false;
    const task = condition.task;
    this.conditionObj = JSON.parse(JSON.stringify(condition));
    this.conditionObj['index'] = index;
    asyncScheduler.schedule(() => {
      this.conditionObj.task = task;
      this.showConditionBlock = true;
    }, 300);
    this.savedConditionInTask = this.conditionObj.conditionInTask;
    this.savedInputType = JSON.parse(JSON.stringify(this.conditionObj.selectInput));
    this.emitConditionalData.emit({ data: condition, type: this.action, list: this.postObject });
  }


  hideIcon(condition) {

    if ((condition.conditionName && condition.conditionName.trim().length > 0)
      || (condition.task && condition.task.trim().length > 0)) {
      return false;
    } else {
      return true;
    }
  }

  handleSingleInputDropdown(event) {
    if (event && this.conditionObj['task']) {
      let task_name = this.conditionObj['task'];
      if (this.type !== 'outLevel') {
        task_name = this.conditionObj['task'].substring(this.conditionObj['task'].indexOf('_') + 1, this.conditionObj['task'].length);
      }
      const inputJson = {
        projectCode: this.SCHEDULETASKDETAILS.projectCode,
        level: this.SCHEDULETASKDETAILS.level,
        task_name: task_name,
        conditionInTask: event,
        type: this.type,
        taskType: this.isMR ? 'MRTask' : 'Poll'

      };
      this.refreshCohortData();
      this._service.handle_single_input_dropdown(inputJson)
        .subscribe(
          response => {
            if (response['status'] === 'success') {
              if (!this.isMR) {
                this.SELECT_INPUT = response['result'];
                if (this.savedInputType) {
                  asyncScheduler.schedule(() => {
                    this.conditionObj.selectInput = this.savedInputType;
                    this.savedInputType = undefined;
                  }, 300);
                }
              } else {
                this.getQuestionIDs(response['result']);
              }
            }
          },
          err => {
            console.log(err);
            toastr.error('Failed to fetch data', 'ERROR');
          }
        );
    }
  }
  inputChange(value, options) {
    if (!value) {
      return false;
    }
    this.refreshCohortData();
    this.isMR = this.checkTypeOfInput(value, options);
    asyncScheduler.schedule(() => {
      if (this.type === 'inLevel') {
        this.conditionInTaskTypes = this.previousLists['conditionInTask'][value];
      } else {
        this.conditionInTaskTypes = this.previousLists1['conditionInTask'][value];
      }
      this.conditionObj['selectInput'] = [];
      if (this.savedConditionInTask) {
        asyncScheduler.schedule(() => {
          this.conditionObj.conditionInTask = this.savedConditionInTask;
          this.savedConditionInTask = undefined;
        }, 300);
      }
    }, 100);
  }
  checkTypeOfInput(value, options) {
    let flag = false;
    options.forEach(element => {
      if (element.value === value && element.taskType === 'MRTask') {
        flag = true;
        return true;
      }
    });
    return flag;
  }
  getQuestionIDs(data) {
    this.questionIDs = [];
    this.questionsMapping = {};
    const temp = [];
    data.forEach(element => {
      temp.push({ label: element.tabname, value: element.id });
      this.questionsMapping[element.id] = element;
    });
    this.questionIDs = temp;
    asyncScheduler.schedule(() => {
      if (this.conditionObj.cohortValue && this.isMR) {
        this.cohortData = Object.assign(this.cohortData, this.conditionObj.cohortValue);
      }
    }, 300);
  }
  cancel() {
    this.reinitialiseConditionObj();
    this.cancelButton = false;
  }


  switchPrimary() {
    // this.postObject['cohortSelected'] = !this.postObject['cohortSelected'];
    if (this.action === 'inLevel') {
      if (this.postObject['inLevelCohortSelected'] == 0) {
        this.postObject['inLevelCohortSelected'] = 1;
      } else {
        this.postObject['inLevelCohortSelected'] = 0;
      }
    }
    if (this.action === 'outLevel') {
      if (this.postObject['outLevelCohortSelected'] == 0) {
        this.postObject['outLevelCohortSelected'] = 1;
      } else {
        this.postObject['outLevelCohortSelected'] = 0;
      }
    }
    this.emitConditionalData.emit({ data: {}, type: this.action, list: this.postObject });
  }


  // to sent cohortsvalues according to api requirement
  manipulateCohorts(type) {
    this.postObject.cohortvalue = [];
    if (this.selectedCohorts) {
      this.selectedCohorts.forEach(element => {
        this.postObject.cohortvalue.push(element.id);
      });
    }
  }
  remove(condition, index) {
    if (!condition.id) {
      this.postObject.conditionsList.splice(index, 1);
    } else {
      this.getDeleteConditionalData(condition, index);
      // this.emitConditionalData.emit({ data: condition, type: 'delete', list: this.postObject });
    }
    this.reinitialiseConditionObj();
  }
  getDeleteConditionalData(data, index) {
    const inputJson = {
      level: this.SCHEDULETASKDETAILS.level,
      projectCode: this.SCHEDULETASKDETAILS.projectCode,
      taskId: this.SCHEDULETASKDETAILS.discussion_id ||
        this.SCHEDULETASKDETAILS.voice_id ||
        this.SCHEDULETASKDETAILS.emailID ||
        this.SCHEDULETASKDETAILS.patientID ||
        this.SCHEDULETASKDETAILS.taskCode ||
        this.SCHEDULETASKDETAILS.Rep_id ||
        this.SCHEDULETASKDETAILS.surveyID ||
        this.SCHEDULETASKDETAILS.tedx_id,
      id: data.id,
      action: 'delete'
    };
    this._service.delete_cohort_data(inputJson)
      .subscribe(
        response => {
          if (response['status'] === 'success') {
            this.postObject.conditionsList.splice(index, 1);
            toastr.success('Deleted! successfully ', 'Success');
          } else {
            toastr.error('Failed to fetch data' + response['message'], 'ERROR');
          }
        },
        err => {
          console.log(err);
          toastr.error('Failed to fetch data', 'ERROR');
        }
      );
  }
  changeHasResponse() {
    this.conditionObj['hasResponse'] = !this.conditionObj['hasResponse'];
  }
  dropDownSelect() {
    if (this.postObject['cohortType'] === 'Response Based') {
      this.hasResponse = true;
      this.ConditionTask = false;
      this.SelectInput = false;
    }
    // tslint:disable-next-line:one-line
    else if (this.postObject['cohortType'] === 'Specific Input') {
      this.hasResponse = false;
      this.ConditionTask = true;
      this.SelectInput = true;
    }
    this.CATEGORY_OPTIONS = [];
  }
  getCohortRow(type) {
    const obj: any = { questionID: this.cohortData.questionID, taskType: 'MRTask' };
    if (this.operatorBasedQuestions.indexOf(type) > -1) {
      obj.value = this.cohortData.value;
      obj.operator = this.cohortData.operator;
    }
    obj.options = this.clone(this.cohortData.options);
    return obj;
  }

  reinitialiseConditionObj() {
    this.conditionObj = {
      conditionName: '',
      cohortType: '',
      task: '',
      conditionInTask: '',
      selectInput: [],
      hasResponse: false
    };
  }
  changeQuestion(value) {
    if (!value) {
      return false;
    }
    if (value && this.questionsMapping[value]) {
      this.questionDetails = this.questionsMapping[value];
      this.cohortOptions = [];
      const temp = [];
      this.questionsMapping[value].dataArray.forEach(element => {
        temp.push({ value: element.id, label: element.value });
      });
      this.cohortOptions = temp;
    }
  }
  refreshCohortData() {
    this.cohortData = {
      options: [],
      questionID: '',
      operator: '>',
      value: null,
      taskType: 'MRTask',
      operatorsList: [
        { value: '<', label: '<' },
        { value: '>', label: '>' },
        { value: '=', label: '=' }
      ]
    };
    this.questionDetails = undefined;
  }

  addcohortList(element, index){
    this.testObject.push(this.conditionObj)
  }
  onKeydown(e, current) {
    if (e.which == 32) {
      return false;
    }

  }
}
