import { Component, OnInit, OnChanges } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AllsetupService } from './services/allsetup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AllsetupService]
})
export class AppComponent implements OnInit, OnChanges {
  title = 'app';
  constructor(public _service: AllsetupService) {

  }
  ngOnInit() {
  }
  ngOnChanges() {
  }

}
