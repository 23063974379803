import { Injectable } from '@angular/core';
// import { RequestOptions } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Response } from '@angular/core';
import { asyncScheduler, Observable, throwError } from 'rxjs';
import 'rxjs/operators';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { AppSettings } from '../configuration/AppSettings';
import { LoaderService } from '../components/shared/loader/loader-service.service';
import * as toastr from 'toastr';
import { EMPTY } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

@Injectable()
export class AllsetupService {
  headers: HttpHeaders;
  //  options: OptionsType;
  private monitoring = {
    pendingRequestsNumber: 0
  };
  constructor(public _http: HttpClient, private _storage: StorageService, private _router: Router, private loaderService: LoaderService) {
    if (!localStorage.getItem('token') || localStorage.getItem('token') === 'undefined') {
      this._router.navigate(['/login']);
    }
  }
  commonGetService(url) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; \
    charset=UTF-8', 'Accept': 'text',
      'token': localStorage.getItem('token'),
      'userID': localStorage.getItem('userID'),
      'userName': localStorage.getItem('userName'),
      'withCredentials': 'true',
      'Access-Control-Allow-Credentials': 'True',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    });
    //this.options = new RequestOptions({ headers: this.headers });
    // this.headers = new Headers();
    // this.headers.append('content-type', 'application/x-www-form-urlencoded');
    // this.headers.append('withCredentials', 'true');
    // this.headers.set('Access-Control-Allow-Credentials', 'True');
    // this.headers.set('Access-Control-Allow-Origin', '*');
    // this.headers.set('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    // this.headers.set('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    const options = { http_headers: this.headers, withCredentials: true };
    this.showLoader();
    this.monitoring.pendingRequestsNumber++;
    let pendingRequest = true;
    let httpReq: any;
    const timeOut = setTimeout(() => {
      if (pendingRequest) {
        this.monitoring.pendingRequestsNumber--;
        toastr.error('Server is taking too much time to respond, please try again', 'ERROR');
        this.onEnd();
      }
    }, 120000 * 10);
    // return this._http.get(url, options)
    //   .subscribe((res) => {
    //     pendingRequest = false;
    //     clearTimeout(timeOut);
    //     return res;
    //   }, (err: any) => {
    //     pendingRequest = false;
    //     clearTimeout(timeOut);
    //     if (err.status === 401 || err.status === 403 || err.status === 440) {
    //       this._storage.api.session.remove(AppSettings.SESSION_KEY.TOKEN);
    //       this._router.navigate(['/login']);
    //       return EMPTY;
    //     } else {
    //       return Observable.throw(err.json().error || 'Server error');
    //     }
    //   }, () => {
    //     this.monitoring.pendingRequestsNumber--;
    //     this.onEnd();
    //   });

    return this._http.get(url, options).pipe(map(res => {
      pendingRequest = false;
      clearTimeout(timeOut);
      return res;
    }, (err: any) => {
      pendingRequest = false;
      clearTimeout(timeOut);
      if (err.status === 401 || err.status === 403 || err.status === 440) {
        this._storage.api.session.remove(AppSettings.SESSION_KEY.TOKEN);
        this._router.navigate(['/login']);
        return EMPTY;
      } else {
        return throwError(err.json().error || 'Server error');
      }
    }), finalize(() => {
      this.monitoring.pendingRequestsNumber--;
      this.onEnd();
    }))



    return httpReq;
  }
  commonPostService(url, input): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; \
    charset=UTF-8', 'Accept': 'text',
      'token': localStorage.getItem('token'),
      'userID': localStorage.getItem('userID'),
      'userName': localStorage.getItem('userName'),
      'withCredentials': 'true',
      'Access-Control-Allow-Credentials': 'True',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    });
    // this.options = new RequestOptions({ headers: this.headers });
    const options = { headers: this.headers, withCredentials: true };
    input.user_id = this._storage.api.local.get('userID');
    this.showLoader();
    this.monitoring.pendingRequestsNumber++;
    let pendingRequest = true;
    let httpReq: any;
    const timeOut = setTimeout(() => {
      if (pendingRequest) {
        this.monitoring.pendingRequestsNumber--;
        toastr.error('Server is taking too much time to respond, please try again', 'ERROR');
        this.onEnd();
      }
    }, 120000 * 10);
    // httpReq = this._http.post(url, input, options)
    //   .subscribe((res) => {
    //     pendingRequest = false;
    //     clearTimeout(timeOut);
    //     let response = res['_body'];
    //     response = JSON.parse(response);
    //     return response;
    //   }, (err: any) => {
    //     pendingRequest = false;
    //     clearTimeout(timeOut);
    //     console.log(err);
    //     if (err.status === 401 || err.status === 403 || err.status === 440) {
    //       this._storage.api.session.remove(AppSettings.SESSION_KEY.TOKEN);
    //       this._router.navigate(['/login']);
    //       return EMPTY;
    //     } else {
    //       return Observable.throw(err.json().error || 'Server error');
    //     }
    //   }, () => {
    //     this.monitoring.pendingRequestsNumber--;
    //     this.onEnd();
    //   });
    // return httpReq;

    return this._http.post(url, input, options).pipe(map(res => {
      pendingRequest = false;
      clearTimeout(timeOut);
      // let response = res['_body'];
      // response = JSON.parse(response);
      return res;
    }, (err: any) => {
      pendingRequest = false;
      clearTimeout(timeOut);
      console.log(err);
      if (err.status === 401 || err.status === 403 || err.status === 440) {
        this._storage.api.session.remove(AppSettings.SESSION_KEY.TOKEN);
        this._router.navigate(['/login']);
        return EMPTY;
      } else {
        return throwError(err.json().error || 'Server error');
      }
    }), finalize(() => {
      this.monitoring.pendingRequestsNumber--;
      this.onEnd();
    }))
  }

  comonnonUploadService(url, input) {
    this.showLoader();
    this.monitoring.pendingRequestsNumber++;
    let pendingRequest = true;
    const timeOut = setTimeout(() => {
      if (pendingRequest) {
        this.monitoring.pendingRequestsNumber--;
        toastr.error('Server taking too much time to process request, please try again', 'ERROR');
        this.onEnd();
      }
    }, 120000 * 10);
    this.headers = new HttpHeaders({
      'Accept': 'q=0.8;application/json;q=0.9',
      'withCredentials': 'true',
      'Access-Control-Allow-Credentials': 'True',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    });
    const options = { headers: this.headers, withCredentials: true };
    return this._http.post(url, input, options).pipe(map(res => {
      pendingRequest = false;
      clearTimeout(timeOut);
      // let response = res['_body'];
      // response = JSON.parse(response);
      return res;
    }, (err: any) => {
      pendingRequest = false;
      clearTimeout(timeOut);
      console.log(err);
      if (err.status === 401 || err.status === 403 || err.status === 440) {
        this._storage.api.session.remove(AppSettings.SESSION_KEY.TOKEN);
        this._router.navigate(['/login']);
        return EMPTY;
      } else {
        return throwError(err.json().error || 'Server error');
      }
    }), finalize(() => {
      this.monitoring.pendingRequestsNumber--;
      this.onEnd();
    }));

  }

  logoutPostService(url, input) {
    // this.headers = new Headers({
    // 'Content-Type': 'application/x-www-form-urlencoded; \
    // charset=UTF-8', 'Accept': 'text', 'token': localStorage.getItem('token'),
    // 'withCredentials': 'true',
    // 'Access-Control-Allow-Credentials': 'True',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    // });
    // this.options = new RequestOptions({ headers: this.headers });
    // this.options = new RequestOptions({ headers: this.headers});
    // input.user_id = this._storage.api.local.get('userID');
    this.showLoader();
    this.monitoring.pendingRequestsNumber++;
    let pendingRequest = true;
    let httpReq: any;
    const timeOut = setTimeout(() => {
      if (pendingRequest) {
        this.monitoring.pendingRequestsNumber--;
        toastr.error('Server is taking too much time to respond, please try again', 'ERROR');
        this.onEnd();
      }
    }, 120000 * 10);
    httpReq = this._http.post(url, input)
      .subscribe((res) => {
        pendingRequest = false;
        clearTimeout(timeOut);
        let response = res['_body'];
        response = JSON.parse(response);
        return response;
      }, (err: any) => {
        pendingRequest = false;
        clearTimeout(timeOut);
        console.log(err);
        if (err.status === 401 || err.status === 403 || err.status === 440) {
          this._storage.api.session.remove(AppSettings.SESSION_KEY.TOKEN);
          this._router.navigate(['/login']);
          return EMPTY;
        } else {
          return throwError(err.json().error || 'Server error');
        }
      }, () => {
        this.monitoring.pendingRequestsNumber--;
        this.onEnd();
      });
    return httpReq;

  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    if (this.monitoring.pendingRequestsNumber === 0) {
      asyncScheduler.schedule(() => {
        this.loaderService.hide();
      }, 1000);
    }
  }
  private onEnd(): void {
    this.hideLoader();
  }
  // service calls
  generate_token(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.generate_token, bodyData);
  }
  EmailRequest(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.EMAIL_TABLE, bodyData);
  }
  Individual_Notification(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.INDIVIDUAL_NOTIFICATION, bodyData);
  }
  Bonus_Point(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.BONUS_POINT, bodyData);
  }
  Publish_Message(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.PUBLISH_MESSAGE, bodyData);
  }
  ConferenceRoom_Tabs(): Observable<any> {
    return this.commonGetService(AppSettings.URL.CONFERENCE_TABS);
  }
  download_filtered_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.download_filtered_data, bodyData);
  }
  web_chatLoad(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.web_chatLoad, bodyData);
  }
  web_chatLoad_firebase(bodyData, url): Observable<any> {
    return this.commonPostService(url, bodyData);
  }
  web_delete_comment(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.web_delete_comment, bodyData);
  }
  update_message_approval_status(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.update_message_approval_status, bodyData);
  }
  web_chatUpdate(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.web_chatUpdate, bodyData);
  }
  reportTable(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.reportTable, bodyData);
  }

  preview(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.preview, bodyData);
  }
  // reportUsers(): Observable<any>{
  //   return this.commonGetService(AppSettings.URL.reportUser);
  // }
  reportUsers(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.reportUser, bodyData);
  }
  reportLevels(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.reportLevels, bodyData);
  }
  reportQuestion(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.reportQuestion, bodyData);
  }
  reportQuestionType(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.reportQuestionType, bodyData);
  }
  graphData(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.graphData, bodyData);
  }
  reportOptions(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.reportOptions, bodyData);
  }
  save_config(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.save_config, bodyData);
  }
  FormJson(): Observable<any> {
    return this.commonGetService(AppSettings.URL.formJson);
  }
  sideMenu_service(): Observable<any> {
    return this.commonGetService(AppSettings.URL.sideMenu);
  }
  add_newProject(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.add_newProject, bodyData);
  }
  editProject(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.edit_project, bodyData);
  }
  getAllUsers_service(bodyData): Observable<any> {
    // return this.commonGetService(AppSettings.URL.all_users_list);
    return this.commonPostService(AppSettings.URL.all_users_list, bodyData);
  }
  add_newUser(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.add_user, bodyData);
  }
  add_newTemplate(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.add_template, bodyData);
  }
  add_newActivity(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.add_activity, bodyData);
  }
  addLevel(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.addLevel, bodyData);
  }
  create_new_level(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.create_new_level, bodyData);
  }
  voicemail_config(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.voicemail_config, bodyData);
  }
  tex_config(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.tex_config, bodyData);
  }
  breakRoom_config(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.breakRoom_config, bodyData);
  }
  discussion_config(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.discussion_config, bodyData);
  }
  email_config(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.email_config, bodyData);
  }
  configure_mr_task(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.configure_mr_task, bodyData);
  }
  clinic_config(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.clinic_config, bodyData);
  }
  rep_config(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.rep_config, bodyData);
  }
  imageUploadService(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.imageUpload, bodyData);
  }
  introimageUploadService(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.introimageUploadService, bodyData);
  }
  intro_image_url(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.intro_image_url, bodyData);
  }
  saveAllUploads(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.saveAllUploadsService, bodyData);
  }
  return_configured_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.return_configured_data, bodyData);
    // return this.commonGetService(AppSettings.URL.return_configured_data);
  }

  delete_cohort_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.delete_automatic_cohort_data, bodyData);
  }
  delete_automatic_chort_table(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.delete_automatic_chort_table, bodyData);
  }

  inputChange(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.inputChange, bodyData);
  }


  handle_single_input_dropdown(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.handle_single_input_dropdown, bodyData);
  }
  handle_image_input_dropdown(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.handle_image_input_dropdown, bodyData);
  }
  save_automatic_cohort_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.save_automatic_cohort_data, bodyData);
  }
  fetch_automatic_cohort_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_automatic_cohort_data, bodyData);
  }
  fetch_the_task_list_from_previous_level(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_the_task_list_from_previous_level, bodyData);
  }
  delete_automatic_cohort_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.delete_automatic_cohort_data, bodyData);
  }


  return_fetch_conditions_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.return_configured_data, bodyData);
  }

  save_conditions_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.return_configured_data, bodyData);
  }
  get_level_details(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.get_level_details, bodyData);
    // return this.commonGetService(AppSettings.URL.get_level_details);
  }
  delete_intro_screen(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.delete_intro_screen, bodyData);
  }


  introFetch(bodyData): Observable<any> {
    // return this.commonGetService(AppSettings.URL.introFetch);
    return this.commonPostService(AppSettings.URL.introFetch, bodyData);
  }

  fetch_existing_config_data(bodyData): Observable<any> {
    // return this.commonGetService(AppSettings.URL.introFetch);
    return this.commonPostService(AppSettings.URL.fetch_existing_config_data, bodyData);
  }

  fetch_intro_url(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_intro_url, bodyData);
  }

  dropdown_details(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.dropdown_details, bodyData);
    // return this.commonGetService(AppSettings.URL.dropdown_details);
  }
  delete_Report(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.delete_Report, bodyData);
    // return this.commonGetService(AppSettings.URL.dropdown_details);
  }
  duplicate_config(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.duplicate_config, bodyData);
    // return this.commonGetService(AppSettings.URL.dropdown_details);
  }
  // chat_details(bodyData): Observable<any>{
  //   // return this.comonnonPostService(AppSettings.URL.dropdown_details, bodyData);
  //   return this.commonGetService(AppSettings.URL.chat_details);
  // }
  transcript_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.transcript_data, bodyData);
    // return this.commonGetService(AppSettings.URL.transcript_data);
  }
  UploadFileService(bodyData: Object): Observable<any> {
    return this.comonnonUploadService(AppSettings.URL.UPLOAD_FILE_REQUEST, bodyData);
  }
  getTableData_dev(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.getTableData_dev, bodyData);
  }
  publish_task_details(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.publish_task_details, bodyData);
  }
  validate_task_details(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.validate_task, bodyData);
  }
  logout_service() {
    return this.logoutPostService(AppSettings.URL.logout_service, {});
  }
  publish_level_details(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.publish_level_details, bodyData);
  }
  publish_project_details(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.publish_project_details, bodyData);
  }
  set_active_level(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.set_active_level, bodyData);
  }
  Schedule_task(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.SCHEDULE_TASK, bodyData);
  }
  archive_level_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.archive_level_data, bodyData);
  }
  archive_project_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.archive_project_data, bodyData);
  }
  DisJson(): Observable<any> {
    return this.commonGetService(AppSettings.URL.disJson);
  }

  // topic_notification
  topic_notification(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.topic_notification, bodyData);
  }
  delete_tasks(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.delete_tasks, bodyData);
  }
  fetch_task_customization(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_task_customization, bodyData);
  }
  fetch_moderators_list(): Observable<any> {
    return this.commonGetService(AppSettings.URL.fetch_moderators_list);
  }
  add_a_moderator(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.add_a_moderator, bodyData);
  }
  save_moderator_wrt_project(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.save_moderator_wrt_project, bodyData);
  }
  manage_resume_functionality(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.manage_resume_functionality, bodyData);
  }
  fetch_moderators_wrt_project(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_moderators_wrt_project, bodyData);
  }
  fetch_conversations(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_conversations, bodyData);
  }
  insert_new_message(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.insert_new_message, bodyData);
  }
  transcript_data_generate_CSV(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.transcript_data_generate_CSV, bodyData);
  }
  task_customization(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.task_customization, bodyData);
  }
  generate_respondent_csv(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.generate_respondent_csv, bodyData);
  }
  download_respondent_csv(): Observable<any> {
    return this.commonGetService(AppSettings.URL.download_respondent_csv);
  }
  getClinicDefaultText(): Observable<any> {
    return this.commonGetService(AppSettings.URL.getClinicDefaultText);
  }
  getRepDefaultText(): Observable<any> {
    return this.commonGetService(AppSettings.URL.getRepDefaultText);
  }
  getGenericDefaultText(): Observable<any> {
    return this.commonGetService(AppSettings.URL.getGenericDefaultText);
  }
  delete_s3_file(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.delete_s3_file, bodyData);
  }
  fetch_individual_conversation(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_individual_conversation, bodyData);
  }
  download_moderator_chat_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.download_moderator_chat_data, bodyData);
  }
  download_all_moderator_chat_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.download_all_moderator_chat_data, bodyData);
  }
  get_download_url(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.get_download_url, bodyData);
  }
  get_overall_project_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.get_overall_project_data, bodyData);
  }
  get_overall_level_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.get_overall_level_data, bodyData);
  }
  individual_progress_details(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.individual_progress_details, bodyData);
  }

  // Level Cohorting - Getting Previous tasks data
  getPreviousTasksForLevelCohorting(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.getTaskDetailForLevelCohorting, bodyData);
    // return this.commonGetService(AppSettings.URL.getTaskDetailForLevelCohorting);

  }
  getLevelForCohort(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.getLevelDetailsForCohort, bodyData);
  }
  getSingleInputForCohort(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.getSingleInputForCohort, bodyData);
  }
  getMrTaskDropdownDataForDiscussion(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.getMrTaskDetailsForDiscussion, bodyData);
  }
  // graphData(): Observable<any>{
  //   return this.commonGetService(AppSettings.URL.graphData);
  // }
  preview_charts(): Observable<any> {
    return this.commonGetService(AppSettings.URL.preview_charts);
  }
  levels_dropdown_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.levels_dropdown_data, bodyData);
  }
  fetch_mr_dropdown_details(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_mr_dropdown_details, bodyData);
  }
  fetch_project_details(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_project_details, bodyData);
  }
  fetch_language_code_dropdown(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_language_code_dropdown, bodyData);
  }
  fetch_parent_text_dropdown(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_parent_text_dropdown, bodyData);
  }
  save_translation(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.save_translation, bodyData);
  }
  save_new_key(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.save_new_key, bodyData);
  }
  fetch_translation(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_translation, bodyData);
  }
  fetch_country_language_dropdown(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_country_language_dropdown, bodyData);
  }
  fetch_country_table(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_country_table, bodyData);
  }
  add_new_country(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.add_new_country, bodyData);
  }
  duplicate_task(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.duplicate_task, bodyData);
  }
  copy_translation(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.copy_translation, bodyData);
  }
  replicate_level(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.replicate_level, bodyData);
  }
  edit_translation(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.edit_translation, bodyData);
  }
  fetch_translation_texts(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_translation_texts, bodyData);
  }
  manage_offline_functionality(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.manage_offline_functionality, bodyData);
  }
  manage_audio_limit_functionality(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.manage_audio_limit_functionality, bodyData);
  }
  save_default_translations(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.save_default_translations, bodyData);
  }
  fetch_translation_dropdowns(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.fetch_translation_dropdowns, bodyData);
  }
  get_default_translations(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.get_default_translations, bodyData);
  }
  get_max_and_min_of_rating(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.get_max_and_min_of_rating, bodyData);
  }
  delete_country(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.delete_country, bodyData);
  }
  // upload excel
  upload_translation_texts(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.upload_translation_texts, bodyData);
  }
  // upload on tab3 web default translation
  upload_web_default_translation(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.upload_web_default_translation, bodyData);
  }

  get_webdefault_translation_keys(): Observable<any> {
    return this.commonGetService(AppSettings.URL.get_webdefault_translation_keys);
  }

  // login duplicate
  login_service(): Observable<any> {
    return this.commonGetService(AppSettings.URL.web_login);
  }
  getTemplate_service(): Observable<any> {
    return this.commonGetService(AppSettings.URL.getTemplate);
  }
  fetch_moderators_wrt_project_duplicate(): Observable<any> {
    return this.commonGetService(AppSettings.URL.fetch_moderators_wrt_project_duplicate);
  }
  fetch_previous_tasks(): Observable<any> {
    return this.commonGetService(AppSettings.URL.fetch_previous_tasks);
  }
  returnConfiguredData(): Observable<any> {
    return this.commonGetService(AppSettings.URL.returnConfiguredData);
  }
  fetchAutomaticCohert(): Observable<any> {
    return this.commonGetService(AppSettings.URL.fetch_automatic_cohert);
  }

  // Json ends

  delete_question_level_cohort_data(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.delete_question_level_cohort_data, bodyData);
  }
  delete_question_level_cohort_table(bodyData): Observable<any> {
    return this.commonPostService(AppSettings.URL.delete_question_level_cohort_table, bodyData);
  }
  logout_user(bodyData): Observable<Comment[]> {
    return this.commonPostService(AppSettings.URL.logout_user, bodyData);
  }
  get_sap_user_list(bodyData): Observable<Comment[]> {
    return this.commonPostService(AppSettings.URL.get_sap_user, bodyData);
  }
  migrate_project_code(bodyData): Observable<Comment[]> {
    return this.commonPostService(AppSettings.URL.migrate_project_code, bodyData);
  }
  migrate_users(bodyData): Observable<Comment[]> {
    return this.commonPostService(AppSettings.URL.migrate_users, bodyData);
  }

  data_refresh(bodyData): Observable<Comment[]> {
    return this.commonPostService(AppSettings.URL.data_refresh, bodyData);
  }
  fetch_report_data(bodyData): Observable<Comment[]> {
    return this.commonPostService(AppSettings.URL.fetch_report_status, bodyData);
  }
  donwload_s3_url(bodyData): Observable<Comment[]> {
    return this.commonPostService(AppSettings.URL.donwload_s3_url, bodyData);
  }
}
