import * as $ from 'jquery';
import { TOKEN } from './login.service';
import { StorageService } from './storage.service';
import { AppSettings } from '../configuration/AppSettings';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { take, finalize } from 'rxjs/operators';
// import { RequestOptions, RequestOptionsArgs } from '@angular/http';


@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  private monitoring = {
    pendingRequestsNumber: 0
  };
  msgError: string = null;
  Response: any;
  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _storage: StorageService) {

  }
  // request(url: string | Request, _options: _headers): Observable<Response> {
  //   this.monitoring.pendingRequestsNumber++;
  //   return this.handleResponse(
  //     this._http.request(url, this.getRequestOptionArgs(_options))).finally(() => {
  //       this.monitoring.pendingRequestsNumber--;
  //     });
  // }
  // get(url: string, options?: RequestOptionsArgs): Observable<Response> {
  //   this.monitoring.pendingRequestsNumber++;
  //   const requestOption = this.getRequestOptionArgs(options);
  //   requestOption.body = '';
  //   return this.handleResponse(this._http.get(url)).finally(() => {
  //     this.monitoring.pendingRequestsNumber--;
  //   });
  // }
  // getWithoutHeaders(url: string) {
  //   this.monitoring.pendingRequestsNumber++;
  //   const requestOption = new RequestOptions();
  //   requestOption.body = '';
  //   return this.handleResponse(this._http.get(url, requestOption)).finally(() => {
  //     this.monitoring.pendingRequestsNumber--;
  //   });
  // }
  // post(url: string, data: any, options?: any): Observable<any> {
  //   this.monitoring.pendingRequestsNumber++;
  //   const body = JSON.stringify(data);
  //   return this.handleResponse(
  //     this._http.post(url, body, options).finally(() => {
  //       this.monitoring.pendingRequestsNumber--;
  //     });
  // }
  post(url: string, data: any, options): Observable<any> {
    this.monitoring.pendingRequestsNumber++;
    const body = JSON.stringify(data);
    return this._http.post(url, data, options);
    // console.log(dataTest);
    // return dataTest;
  }

  // form_post(url: string, data: any, options?: RequestOptionsArgs): Observable<Response> {
  //   this.monitoring.pendingRequestsNumber++;
  //   return this.handleResponse(this._http.post(url, data)).finally(() => {
  //     this.monitoring.pendingRequestsNumber--;
  //   });
  // }
  // form_data(url: string, data: any, options?: RequestOptionsArgs): Observable<Response> {
  //   this.monitoring.pendingRequestsNumber++;
  //   const formData = new FormData();
  //   // tslint:disable-next-line:forin
  //   for (const k in data) {
  //     formData.append(k, data[k]);
  //   }
  //   return this.handleResponse(this._http.post(url, formData, this.getRequestOptionArgs(options, false))).finally(() => {
  //     this.monitoring.pendingRequestsNumber--;
  //   });
  // }

  // put(url: string, data: any, options?: RequestOptionsArgs): Observable<Response> {
  //   this.monitoring.pendingRequestsNumber++;
  //   const body = JSON.stringify(data);
  //   return this.handleResponse(this._http.put(url, body, this.getRequestOptionArgs(options))).finally(() => {
  //     this.monitoring.pendingRequestsNumber--;
  //   });
  // }
  // delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
  //   this.monitoring.pendingRequestsNumber++;
  //   return this.handleResponse(this._http.delete(url, options)).finally(() => {
  //     this.monitoring.pendingRequestsNumber--;
  //   });
  // }
  // getRequestOptionArgs(options?: RequestOptionsArgs, useContentType: Boolean = true): RequestOptionsArgs {
  //   if (options == null) {
  //     options = new RequestOptions();
  //   }
  //   if (options.headers == null) {
  //     options.headers = new Headers();
  //   }
  //   const token: TOKEN = this._storage.api.session.get(AppSettings.SESSION_KEY.TOKEN);
  //   if (token && token.access_token) {
  //     options.headers.append('Authorization', 'Bearer ' + token.access_token);
  //   }
  //   if (!options.headers.get('accept') && useContentType) {
  //     options.headers.append('accept', 'application/json');
  //   }
  //   if (!options.headers.get('content-type') && useContentType) {
  //     options.headers.append('content-type', 'application/json');
  //   }
  //   return options;
  // }


  // public get(url): Observable<any> {
  //   return this._http.get(url);
  // }

  // public post(url, params): Observable<any> {
  //   return this._http.post(url, params);
  // }



  // handleResponse(observable: Observable<Response>): Observable<Response> {
  //   return observable.subscribe(res => { },
  //     (err) => {
  //       if (err.status === 401 && !err.url.endsWith('api/account/login')) {
  //         $('#msgErrorCont').css('visibility', 'visible');
  //         this._storage.api.session.remove(AppSettings.SESSION_KEY.TOKEN);
  //         this._router.navigate(['/login']);
  //         return Observable.throwError(err);
  //       } else {
  //         return Observable.throwError(err);
  //       }
  //     }),finally(() => {
  //       if (this.monitoring.pendingRequestsNumber === 0) {
  //         //      this._spinnerService.done();
  //       }
  //     });
  // }
  public extractData(res: Response) {
    const body = res.json();
    if (body['success']) {
      return body['result'] || {};
    }
    return throwError('There is some problem');
  }
  public handleError(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg);
    return throwError(errMsg);
  }
  public handleLoginError(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg);
    return throwError(errMsg);
  }
}
