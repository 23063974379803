import { Injectable } from '@angular/core';

@Injectable()
export class GlobalServiceService {

  constructor() { }

  public checkCohertList(data, inLevel?) {
    const checkKey = inLevel ? 'inLevel' : 'outLevel';
    if (data && data.length > 0) {
      let returnKey = false;
      data.forEach(element => {
        element.conditionsList.forEach(el => {
          if (el.type === checkKey) {
            returnKey = true;
            return returnKey;
          }
          });
        });
      return returnKey;
    }
    return false;
  }

  public emittedCondition(data, inLevel?) {
    const key = inLevel ? 'inLevelCohortSelected' : 'outLevelCohortSelected';
    if (data && data.list && data.list[key]) {
      return 1;
    } else if (data && data.obj && data.obj[key]) {
      return 1;
    } return 0;
  }

}
