import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-nav-side-bar',
  templateUrl: './nav-side-bar.component.html',
  styleUrls: ['./nav-side-bar.component.css'],
  providers: [StorageService]
})
export class NavSideBarComponent implements OnInit {
  path: any;
  constructor(public router: Router, public _storage: StorageService) { }
  activeNav: string;
  ngOnInit() {
    this.path = this.router.url;
    const lastURL = this.path.split(/[\s/]+/).pop();
    this.activeNav = lastURL;
    $('.subLi').click(function () {
      if ($(this).hasClass('closed')) {
        $(this).find('a').attr('aria-expanded', 'true');
        $(this).find('ul').attr('aria-expanded', 'true');
        $(this).find('ul').addClass('in');
        $(this).removeClass('closed');
      } else {
        $(this).addClass('closed');
        $(this).find('a').attr('aria-expanded', 'false');
        $(this).find('ul').attr('aria-expanded', 'false');
        $(this).find('ul').removeClass('in');
      }
    });
  }

  moveToLandingPage() {
    this.router.navigate(['landing']);
  }
  moveToasetup() {
    this.router.navigate(['landing/setting-component/analyzer-setup']);
    this.activeNav = 'analyzer-setup';
  }
  moveToparameter() {
    this.router.navigate(['landing/setting-component/parameter-setup']);
    this.activeNav = 'parameter-setup';
  }
  moveToOrganisation() {
    this.router.navigate(['landing/setting-component/organisation-component']);
    this.activeNav = 'organisation-component';
  }
  moveTousersetup() {
    this.router.navigate(['landing/setting-component/user-setup']);
    this.activeNav = 'user-setup';
  }
  moveToboundarysetup() {
    this.router.navigate(['landing/setting-component/boundary-setup']);
    this.activeNav = 'boundary-setup';
  }
  moveTostation() {
    this.router.navigate(['landing/setting-component/station-setup']);
    this.activeNav = 'station-setup';
  }
  moveToRules() {
    this.router.navigate(['landing/setting-component/rule-setup']);
    this.activeNav = 'rule-setup';
  }
  moveTonetworksetup() {
    this.router.navigate(['landing/setting-component/network-setup']);
    this.activeNav = 'network-setup';
  }
  TestPage() {
    this.router.navigate(['landing/DFM-Testing']);
  }

}
