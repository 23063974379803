import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ApplicationRef, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardService } from './services/auth-guard.service';
// import { Logger, Options } from "angular2-logger/core";
import { SharedModule } from "./components/shared/shared.module";
import { LoaderCommonComponent } from './components/shared/loader/loader-common.component';
import { LoaderService } from './components/shared/loader/loader-service.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from "ng2-file-upload";
import { GlobalServiceService } from './global-service.service';
//  import { EditorModule } from '@tinymce/tinymce-angular';
// import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
// import { AngularFireModule } from 'angularfire2';
import { environment } from '../environments/environment';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
// import { BrowserModule } from '@angular/platform-browser';
// import { CKEditorModule } from 'ng2-ckeditor';
// import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgxEditorModule } from 'ngx-editor';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    MatProgressBarModule,
    FormsModule,
    NgSelectModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    FileUploadModule,
    // EditorModule,
    // RichTextEditorAllModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxEditorModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [LoaderCommonComponent],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuardService,
    // Logger,
    // Options,
    LoaderService,
    GlobalServiceService,
    // CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
