<aside class="left-sidebar">
  <!-- Sidebar scroll-->
  <div class="scroll-sidebar">
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav marqueeUl">
          <ul id="sidebarnav">
              <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="fa fa-cog whiteColor"></i><span class="hide-menu"> Settings <span class="label label-rouded label-danger pull-right">25</span></span></a>
                  <ul aria-expanded="false" class="collapse">
                      <li class="subLi closed"> <a class="has-arrow" href="javascript:void(0)" aria-expanded="false">Sample</a>
                          <ul aria-expanded="false" class="collapse">
                              <li><a href="javascript:void(0)">Sample Category</a></li>
                              <li><a href="javascript:void(0)">Sample Master</a></li>
                              <li><a href="javascript:void(0)">Sample Detail Master</a></li>
                          </ul>
                      </li>
                      <li class="subLi closed"> <a class="has-arrow" href="javascript:void(0)" aria-expanded="false">Group</a>
                          <ul aria-expanded="false" class="collapse">
                              <li><a href="javascript:void(0)">Group Master</a></li>
                              <li><a href="javascript:void(0)">Sub Group Master</a></li>
                          </ul>
                      </li>
                      <li class="subLi closed"> <a class="has-arrow" href="javascript:void(0)" aria-expanded="false">Test</a>
                          <ul aria-expanded="false" class="collapse">
                              <li><a href="javascript:void(0)">Test Master</a></li>
                              <li><a href="javascript:void(0)">Test Method</a></li>
                              <li><a href="javascript:void(0)">Test Configuration</a></li>
                          </ul>
                      </li>
                      <li class="subLi closed"> <a class="has-arrow" href="javascript:void(0)" aria-expanded="false">Sub Test</a>
                          <ul aria-expanded="false" class="collapse">
                              <li><a href="javascript:void(0)">Sub Test Master</a></li>
                              <li><a href="javascript:void(0)">Sub Test Spec</a></li>
                          </ul>
                      </li>
                      <li class="subLi"> <a class="has-arrow" href="javascript:void(0)" aria-expanded="false">Sub Parameter</a>
                          <ul aria-expanded="false" class="collapse">
                              <li><a href="javascript:void(0)">Sub Param master</a></li>
                              <li><a href="javascript:void(0)">Sub Param Config</a></li>
                          </ul>
                      </li>
                      <li class="subLi closed"> <a class="has-arrow" href="javascript:void(0)" aria-expanded="false">Priority</a>
                          <ul aria-expanded="false" class="collapse">
                              <li><a href="javascript:void(0)">Sample</a></li>
                              <li><a href="javascript:void(0)">Group</a></li>
                              <li><a href="javascript:void(0)">SubGroup</a></li>
                              <li><a href="javascript:void(0)">SubTest</a></li>
                              <li><a href="javascript:void(0)">SubParameter</a></li>
                              <li><a href="javascript:void(0)">Report</a></li>
                              <li><a href="javascript:void(0)">Specification</a></li>
                          </ul>
                      </li>
                      <li> <a href="javascript:void(0)">Country</a>
                      </li>
                      <li class="subLi closed"> <a class="has-arrow" href="javascript:void(0)" aria-expanded="false">Customer</a>
                          <ul aria-expanded="false" class="collapse">
                              <li><a href="javascript:void(0)">Customer Category</a></li>
                              <li><a href="javascript:void(0)">Customer Master</a></li>
                              <li><a href="javascript:void(0)">Contact Master</a></li>
                          </ul>
                      </li>
                      <li> <a href="javascript:void(0)">Specification</a>
                      </li>
                      <li> <a href="javascript:void(0)">Contract</a>
                      </li>
                      <li> <a href="javascript:void(0)">Tax</a>
                      </li>
                      <li> <a href="javascript:void(0)">Additional Charge</a>
                      </li>
                      <li> <a  href="javascript:void(0)">Currency</a>
                      </li>
                      <li> <a href="javascript:void(0)">Terms and Conditions</a>
                      </li>
                      <li class="subLi closed"> <a class="has-arrow" href="javascript:void(0)" aria-expanded="false">Departments</a>
                          <ul aria-expanded="false" class="collapse">
                              <li><a href="javascript:void(0)">Departments</a></li>
                              <li><a href="javascript:void(0)">Users</a></li>
                              <li><a href="javascript:void(0)">User Roles</a></li>
                              <li><a href="javascript:void(0)">User Networks</a></li>
                          </ul>
                      </li>
                  </ul>
              </li>  
              <li> <a (click)="TestPage()" class="has-arrow waves-effect waves-dark" href="javascript:void(0)"><i class="fa fa-eye whiteColor"></i><span class="hide-menu"> Test <span class="label label-rouded label-danger pull-right">25</span></span></a></li>
          </ul>
      </nav>
      <!-- End Sidebar navigation -->
  </div>
  <!-- End Sidebar scroll-->
</aside>