<nav class="navbar navCustom navbar-expand-sm navbar-dark">
      <div class="">
            <a class="navbar-brand" href="javascript:void(0)">MomentZS</a>
      </div>
      <button class="btn btn-default whiteColor btnLogOut" (click)="onLoggedout()">
            <i class="fa fa-power-off"></i> Logout
      </button>
 </nav>
 <nav class="navbar navbar-expand-sm marqueeUl navbar-dark">
        <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link main_menu email" (click)="navigateTo('')" href="javascript:void(0)">Email</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link main_menu patient-output" (click)="navigateTo('patient-output')" href="javascript:void(0)">Patient Output</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link main_menu progress-comp"(click)="navigateTo('progress-comp')"  href="javascript:void(0)">Progress</a>
                </li>
                <li class="nav-item dropdown navLiLast">
                    <a class="nav-link level1 audio-data dropdown-toggle" href="javascript:void(0)" id="navbardrop" data-toggle="dropdown">
                         Patient - Audio Data 
                    </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item sub_menuLink" id="patientTumor" href="javascript:void(0)" (click)="navigateTo('audio-data/Audio_Details')">Patient Tumour</a>
                        <a class="dropdown-item sub_menuLink" href="javascript:void(0)" (click)="navigateTo('audio-data/Audio_Details_followup')">Follow Up</a>
                    </div>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link main_menu audio-data" (click)="navigateTo('audio-data')" href="javascript:void(0)">Patient - Audio Data</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link main_menu conference-room" (click)="navigateTo('conference-room')" href="javascript:void(0)">Conference Room</a>
                </li>
                <li class="nav-item">
                        <a class="nav-link main_menu break-room" (click)="navigateTo('break-room')" href="javascript:void(0)">Break Room</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link main_menu notification" (click)="navigateTo('notification')" href="javascript:void(0)">Notification</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link main_menu voice-rec" (click)="navigateTo('voice-rec')" href="javascript:void(0)">Voicemail Recordings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link main_menu ted-ex" (click)="navigateTo('ted-ex')" href="javascript:void(0)">TEDx Talk</a>
                </li>
                <li class="nav-item">
                     <a class="nav-link main_menu user-details" (click)="navigateTo('user-details')" href="javascript:void(0)">User Details</a>
                </li>
                <!-- Dropdown -->
                <li class="nav-item dropdown navLiLast">
                    <a class="nav-link level1 tap-stream dropdown-toggle" href="javascript:void(0)" id="navbardrop" data-toggle="dropdown">
                         Tap Stream
                    </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item sub_menuLink" href="javascript:void(0)" (click)="navigateTo('tap-stream/tap_rep')">Tap Rep</a>
                        <a class="dropdown-item sub_menuLink" href="javascript:void(0)" (click)="navigateTo('tap-stream/tap_patient')">Tap Patient</a>
                    </div>
                </li>
                </ul>   
                <!-- <form class="form-inline pull-right">
                        <input class="form-control mr-sm-2" type="text" placeholder="filter">
                </form>  -->
      </nav>