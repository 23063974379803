import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';


import { LoginService } from '../services/login.service';
import { AuthGuardService } from '../services/auth-guard.service';
import { AuthHttpService } from '../services/auth-http.service';
import { StorageService } from '../services/storage.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('../components/login/login.module').then(m => m.LoginModule) },
  { path: 'admin', loadChildren: () => import('../components/admin/admin.module').then(m => m.AdminModule) },
  { path: 'team-member', loadChildren: () => import('../components/team-member/team-member.module').then(m => m.TeamMemberModule) },
  { path: 'landing', loadChildren: () => import('../components/landing-page/landing-page.module').then(m => m.LandingPageModule), canActivate: [AuthGuardService] }
  // { path: 'landing', loadChildren: '../components/landing-page/landing-page.module#LandingPageModule'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}),FormsModule,NgSelectModule],
  exports: [RouterModule],
  providers: [LoginService, StorageService]
})
export class AppRoutingModule { }
